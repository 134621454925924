@font-face {
  font-family: 'Poppins';
  /*Can be any text*/
  src: local('Poppins'),
    url('../../../fonts/Poppins-Medium.ttf') format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'Poppins';
  /*Can be any text*/
  src: local('Poppins'),
    url('../../../fonts/Poppins-Black.ttf') format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'Poppins';
  /*Can be any text*/
  src: local('Poppins'),
    url('../../../fonts/Poppins-BlackItalic.ttf') format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'Poppins';
  /*Can be any text*/
  src: local('Poppins'),
    url('../../../fonts/Poppins-Bold.ttf') format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'Poppins';
  /*Can be any text*/
  src: local('Poppins'),
    url('../../../fonts/Poppins-BoldItalic.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Poppins';
  /*Can be any text*/
  src: local('Poppins'),
    url('../../../fonts/Poppins-ExtraBold.ttf') format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'Poppins';
  /*Can be any text*/
  src: local('Poppins'),
    url('../../../fonts/Poppins-ExtraBoldItalic.ttf') format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'Poppins';
  /*Can be any text*/
  src: local('Poppins'),
    url('../../../fonts/Poppins-ExtraLight.ttf') format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'Poppins';
  /*Can be any text*/
  src: local('Poppins'),
    url('../../../fonts/Poppins-ExtraLightItalic.ttf') format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'Poppins';
  /*Can be any text*/
  src: local('Poppins'),
    url('../../../fonts/Poppins-Italic.ttf') format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'Poppins';
  /*Can be any text*/
  src: local('Poppins'),
    url('../../../fonts/Poppins-Light.ttf') format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'Poppins';
  /*Can be any text*/
  src: local('Poppins'),
    url('../../../fonts/Poppins-LightItalic.ttf') format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'Poppins';
  /*Can be any text*/
  src: local('Poppins'),
    url('../../../fonts/Poppins-MediumItalic.ttf') format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'Poppins';
  /*Can be any text*/
  src: local('Poppins'),
    url('../../../fonts/Poppins-SemiBold.ttf') format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'Poppins';
  /*Can be any text*/
  src: local('Poppins'),
    url('../../../fonts/Poppins-SemiBoldItalic.ttf') format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'Poppins';
  /*Can be any text*/
  src: local('Poppins'),
    url('../../../fonts/Poppins-Thin.ttf') format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'Poppins';
  /*Can be any text*/
  src: local('Poppins'),
    url('../../../fonts/Poppins-ThinItalic.ttf') format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'Poppins';
  /*Can be any text*/
  src: local('Poppins'),
    url('../../../fonts/Poppins-Regular.ttf') format('truetype');
  font-weight: 500;
}
@import url('../../blocks/dashboard/src/SchoolAdmin/TeacherCalendar/react-big-calendar.css');
@import url('../../blocks/dashboard/src/SchoolAdmin/TeacherCalendar/calendar.css');
@import url('../../blocks/analytics/src/components/Calendar.css');

.canvas-container{
  height: 100% !important;
  width: 100% !important;
}