@media (max-width: 600px) {
  .rbc-label.rbc-time-header-gutter {
    display: none; 
  }

  .rbc-time-view .rbc-row {
    overflow-x: scroll;
    overflow-y: hidden;
    min-height: 110px;
    -ms-overflow-style: none;  
    scrollbar-width: none; 
  }
  .rbc-time-view .rbc-row::-webkit-scrollbar { 
    display: none; 
  }

  .rbc-header {
    height: 0;
  }

  .rbc-row-segment {
    margin-bottom: 6px; 
    max-width: 100% !important;
  }

  .rbc-day-slot .rbc-event-content {
    width: 50%;
  }
}
